<template>
  <div>
      <v-alert v-show="voted" type="success" border="left" outlined icon="fa-thumbs-up">
        <slot name="voted" :item="votedItem">
        Thanks for sharing your feedback! Please <a :href="`mailto:${tenant.contactEmail}?subject=${encodeURIComponent('[Feedback] ' + question)}`">send us a message with additional feedback</a> if you have more in-depth feedback you'd like to share.
        </slot>
      </v-alert>
      <v-alert v-if="!isHidden" v-show="!voted" type="info" border="left" outlined>
        <p class="text-muted mb-2">We would like to ask one quick question. It only takes one click and would help us optimize {{tenant.name}}. Thanks!</p>
        <h3 class="mb-4">{{question}}</h3>
        <v-btn v-for="(item, idx) in items" :key="idx" small class="mr-4 mb-4" @click="processClick(item)">{{item.text}}</v-btn>
        <v-btn outlined small color="grey darken-2" class="mr-4 mb-4" @click="hide"><v-icon small class="mr-2">fal fa-times-circle</v-icon> Hide</v-btn>
      </v-alert>
  </div>
</template>

<script>
import profileService from "@/services/profileService";
import VueCookies from 'vue-cookies'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "QuickSurvey",
  props: {
    name: String,
    question: String,
    items: Array,
  },
  data: function() {
    return {
      tenant: tenant,
      hidden: false,
      voted: false,
      votedItem: null,
    };
  },
  mounted() {
    //VueCookies.remove(`survey-${this.name}`);
  },
  methods: {

    async processClick(item) {
      this.$analytics.event(this.name, { 'event_category': 'survey', 'event_label': item.value });
      console.log('Clicked on ', item.value);
      this.votedItem = item;
      this.voted = true;
      this.setHideCookie();
      await profileService.vote(this.name, item.value);
      this.$emit('changed', item.value);
    },

    hide() {
      this.$analytics.event(this.name, { 'event_category': 'survey', 'event_label': 'hide' });
      this.setHideCookie();
      this.hidden = true;
    },

    setHideCookie() {
      VueCookies.set(`survey-${this.name}`, true, '30d');
    },

  },
  computed: {
    isHidden() {
      const cookieValue = VueCookies.get(`survey-${this.name}`);
      return this.hidden || cookieValue;
    },
  },

};
</script>

<style scoped>
</style>